import React from "react";
import ClientsSection from "./components/ClientsSection";

const AboutSection = React.lazy(() => import("./components/AboutSection"));
const ServicesSection = React.lazy(
  () => import("./components/ServicesSection")
);
const NavBar = React.lazy(() => import("./components/NavBar"));
const TrackSection = React.lazy(() => import("./components/TrackSection"));
const FeaturesSection = React.lazy(
  () => import("./components/FeaturesSection")
);
const IntegrationSection = React.lazy(
  () => import("./components/IntegrationSection")
);
const CoverageSection = React.lazy(
  () => import("./components/CoverageSection")
);
const ContactSection = React.lazy(() => import("./components/ContactSection"));
const FooterSection = React.lazy(() => import("./components/FooterSection"));
// import ReactHelmet from "react-helmet";
export default function App() {
  // const loading = useAppSelector(getAppLoading);
  return (
    <>
      <React.Suspense fallback={null}>
        <NavBar />
        <TrackSection />
        <AboutSection />
        <ServicesSection />
        <FeaturesSection />
        <IntegrationSection />
        <CoverageSection />
        <ClientsSection />
        <ContactSection />
        <FooterSection />
      </React.Suspense>
    </>
  );
}
